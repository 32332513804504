import ErrorReporter from '@peakon/error-reporter';

/**
 * NOTE: Need to use ENV from global here scope to avoid circular dependency
 */
export const errorReporter = new ErrorReporter({
  accessToken: ENV.rollbar ? ENV.rollbar.client : '',
  environment: ENV.clusterEnv,
  version: ENV.version,
});

export type ErrorReporterType = typeof errorReporter;
